"use strict";
/*eslint-disable camelcase*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.get = void 0;
const utils_1 = require("./internal/utils");
const icons_1 = require("./plugins/icons");
const builtins = {
    get arrow_upward() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/navigation/svg/production/ic_arrow_upward_48px.svg");
    },
    get arrow_downward() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/navigation/svg/production/ic_arrow_downward_48px.svg");
    },
    get edit() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/image/svg/production/ic_edit_48px.svg");
    },
    get add() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/content/svg/production/ic_add_48px.svg");
    },
    get star() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/toggle/svg/production/ic_star_24px.svg");
    },
    get star_border() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/toggle/svg/production/ic_star_border_24px.svg");
    },
    get star_half() {
        return require("cheetah-grid-icon-svg-loader!material-design-icons/toggle/svg/production/ic_star_half_24px.svg");
    },
};
function get() {
    return (0, utils_1.extend)(builtins, icons_1.icons);
}
exports.get = get;
